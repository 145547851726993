@use '../../../styles/sizes' as *;
@use '../../../styles/mediaqueries' as *;

.main-wrapper {
  padding: $page-padding-mobile;
  @include media(mediumScreen, bigScreen) {
    padding: $page-padding;
  }
}

.content-wrapper {
  max-width: $max-width-content;
  margin: 0 auto;

  @include media(mediumScreen, bigScreen) {
    min-height: $min-content-height;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  > div {
    width: 100%;
  }
}
