// Courier Prime

@font-face {
  font-family: 'CourierPrime';
  font-weight: 400;
  src:
    local('CourierPrime'),
    url(../fonts/CourierPrime/CourierPrime-Regular.ttf) format('truetype');
  font-display: fallback;
}

@font-face {
  font-family: 'CourierPrime';
  font-weight: 400;
  font-style: italic;
  src:
    local('CourierPrime'),
    url(../fonts/CourierPrime/CourierPrime-Italic.ttf) format('truetype');
  font-display: fallback;
}

@font-face {
  font-family: 'CourierPrime';
  font-weight: 700;
  src:
    local('CourierPrime'),
    url(../fonts/CourierPrime/CourierPrime-Bold.ttf) format('truetype');
  font-display: fallback;
}

@font-face {
  font-family: 'CourierPrime';
  font-weight: 700;
  font-style: italic;
  src:
    local('CourierPrime'),
    url(../fonts/CourierPrime/CourierPrime-BoldItalic.ttf) format('truetype');
  font-display: fallback;
}

// Rubik

@font-face {
  font-family: 'Rubik';
  font-weight: 300;
  src:
    local('Rubik'),
    url(../fonts/Rubik/Rubik-Light.ttf) format('truetype');
  font-display: fallback;
}

@font-face {
  font-family: 'Rubik';
  font-weight: 300;
  font-style: italic;
  src:
    local('Rubik'),
    url(../fonts/Rubik/Rubik-LightItalic.ttf) format('truetype');
  font-display: fallback;
}

@font-face {
  font-family: 'Rubik';
  font-weight: 400;
  src:
    local('Rubik'),
    url(../fonts/Rubik/Rubik-Regular.ttf) format('truetype');
  font-display: fallback;
}

@font-face {
  font-family: 'Rubik';
  font-weight: 400;
  font-style: italic;
  src:
    local('Rubik'),
    url(../fonts/Rubik/Rubik-Italic.ttf) format('truetype');
  font-display: fallback;
}

@font-face {
  font-family: 'Rubik';
  font-weight: 500;
  src:
    local('Rubik'),
    url(../fonts/Rubik/Rubik-Medium.ttf) format('truetype');
  font-display: fallback;
}

@font-face {
  font-family: 'Rubik';
  font-weight: 500;
  font-style: italic;
  src:
    local('Rubik'),
    url(../fonts/Rubik/Rubik-MediumItalic.ttf) format('truetype');
  font-display: fallback;
}

@font-face {
  font-family: 'Rubik';
  font-weight: 600;
  src:
    local('Rubik'),
    url(../fonts/Rubik/Rubik-SemiBold.ttf) format('truetype');
  font-display: fallback;
}

@font-face {
  font-family: 'Rubik';
  font-weight: 600;
  font-style: italic;
  src:
    local('Rubik'),
    url(../fonts/Rubik/Rubik-SemiBoldItalic.ttf) format('truetype');
  font-display: fallback;
}

@font-face {
  font-family: 'Rubik';
  font-weight: 700;
  src:
    local('Rubik'),
    url(../fonts/Rubik/Rubik-Bold.ttf) format('truetype');
  font-display: fallback;
}

@font-face {
  font-family: 'Rubik';
  font-weight: 700;
  font-style: italic;
  src:
    local('Rubik'),
    url(../fonts/Rubik/Rubik-BoldItalic.ttf) format('truetype');
  font-display: fallback;
}

@font-face {
  font-family: 'Rubik';
  font-weight: 800;
  src:
    local('Rubik'),
    url(../fonts/Rubik/Rubik-ExtraBold.ttf) format('truetype');
  font-display: fallback;
}

@font-face {
  font-family: 'Rubik';
  font-weight: 800;
  font-style: italic;
  src:
    local('Rubik'),
    url(../fonts/Rubik/Rubik-ExtraBoldItalic.ttf) format('truetype');
  font-display: fallback;
}

@font-face {
  font-family: 'Rubik';
  font-weight: 900;
  src:
    local('Rubik'),
    url(../fonts/Rubik/Rubik-Black.ttf) format('truetype');
  font-display: fallback;
}

@font-face {
  font-family: 'Rubik';
  font-weight: 900;
  font-style: italic;
  src:
    local('Rubik'),
    url(../fonts/Rubik/Rubik-BlackItalic.ttf) format('truetype');
  font-display: fallback;
}
