@use '../../../styles/sizes' as *;

.menu {
  display: flex;
  gap: 30px;
  justify-content: space-evenly;
  align-items: center;

  height: $menu-bar-height;
  margin-top: 2vh;
  margin-bottom: $menu-bar-margin-bottom;

  button {
    appearance: none;
    cursor: pointer;
    border: none;
    background: none;
  }

  a {
    padding-bottom: 10px;
  }

  a:hover,
  .active {
    background-image: url('../../../img/UnderlineLight.svg');
    background-size: 70px;
    background-position: bottom;
    background-repeat: no-repeat;
    text-decoration: none;
  }

  &Dark {
    a:hover,
    .active {
      background-image: url('../../../img/UnderlineDark.svg');
    }
  }
}

.menu-icon {
  height: 30px;
  width: auto;
  vertical-align: middle;
}
