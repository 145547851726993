$max-width: 900px;

$column-gap: 60px;
$row-gap: 30px;
$row-gap-mobile: 15px;

$max-width-content: $max-width - $column-gap * 2;

$padding-width: 20px;
$page-padding: 0 $padding-width;
$page-padding-mobile: $row-gap-mobile $padding-width;

$menu-bar-height: 10vh;
$menu-bar-margin-bottom: 5vh;

$min-content-height: 80vh - $menu-bar-height - $menu-bar-margin-bottom;
