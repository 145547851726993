@use '../../../styles/typography' as *;
@use '../../../styles/colors' as *;

.markdown {
  padding: 30px;

  &_light {
    background-color: white;

    h5,
    a {
      color: $light-accent;
    }

    .inlineCode {
      color: $light-code-text;
    }
  }
  &_dark {
    background-color: none;

    h5,
    a {
      color: $dark-accent;
    }

    .inlineCode {
      color: $dark-code-text;
    }
  }

  h1 {
    @include heading;
    font-weight: 600;
  }

  h2 {
    @include subHeading;
    font-weight: 600;
    margin-top: 1.5em;
    margin-bottom: 0.5em;
  }

  h3 {
    font-weight: 600;
    margin-top: 0.8em;
    margin-bottom: 0.5em;
  }

  h5 {
    font-size: 14px;
  }

  p {
    font-family: 'Rubik', Arial, Helvetica, sans-serif;
  }

  p:first-of-type {
    margin-top: 2em;
  }

  code {
    display: block;
    padding: 10px;
    overflow-x: auto;
  }

  .inlineCode {
    display: inline;
    overflow: initial;
    padding: 0;
    border: none;
    font-family: 'Roboto Mono', monospace;
    word-wrap: break-word;
  }
}
