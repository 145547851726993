@use './colors' as *;

@mixin baseFontColor {
  color: $light-text;
}

@mixin baseFont {
  font-family: 'CourierPrime', Arial, Helvetica, sans-serif;
  font-weight: 400;
}

@mixin baseFontSize {
  font-size: 18px;
}

@mixin textBody {
  font-weight: 400;
  line-height: 1.5;
}

@mixin heading {
  font-weight: 600;
  font-size: 36px;
  margin-bottom: 0.3em;
}

@mixin subHeading {
  font-weight: 400;
  font-size: 26px;
}

.textHeading {
  @include heading;
  font-family: 'Rubik', Arial, Helvetica, sans-serif;
  text-transform: uppercase;
}

.textSubHeading {
  @include subHeading;
}
