@use './colors' as *;
@use './fonts' as *;
@use './typography' as *;
@use './sizes' as *;
@use './mediaqueries' as *;

* {
  margin: 0;
  padding: 0;
}

body {
  @include baseFont;
  @include baseFontColor;
  @include baseFontSize;
  line-height: 1.2;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $dark-background;
}

p {
  @include textBody;
  margin-bottom: 1em;
}

button {
  @include baseFont;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

button {
  appearance: none;
  border: none;
  display: flex;
  cursor: pointer;
  background: none;
}

ol {
  list-style: inside decimal;
}

ul {
  list-style: inside;
}

li {
  margin-bottom: 1em;
}

.textAccentColor {
  color: $dark-accent;
}
