.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;

  width: 100%;
  height: 100%;
  grid-gap: 10px;

  .imageContainer {
    background-size: cover;
    background-position: center;
    min-height: 200px;
    filter: grayscale(100%);
    cursor: pointer;
    box-sizing: border-box;
  }

  .imageContainer:hover {
    filter: grayscale(0);
    border: 3px solid #f4b324;
  }
}
