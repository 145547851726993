@use '../../../styles/colors' as *;
@use '../../../styles/mediaqueries' as *;

.home {
  a {
    text-decoration: underline;
  }
}

.contact {
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 20px;
  margin: 40px 0px;
  align-items: center;
  justify-content: space-evenly;

  p {
    margin: 0;
  }

  span img {
    vertical-align: middle;
  }

  @include media(mediumScreen, bigScreen) {
    gap: 30px;
    padding: 30px;
    max-width: max-content;
  }

  &_light {
    background-color: $light-secondary-background;
  }
  &_dark {
    background-color: $dark-secondary-background;
  }
}
