@use '../../../styles/_colors.scss' as *;
@use '../../../styles/_mediaqueries' as *;
@use '../../../styles/_typography.scss' as *;

.imageViewer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  height: 100%;

  > img {
    width: 100%;
    align-self: center;
  }

  .imageCaption {
    @include textBody;
    color: $light-text;
    margin: 0;
    font-size: 14px;

    @include media(mediumScreen, bigScreen) {
      font-size: 18px;
    }
  }

  .imageDetail {
    @include textBody;
    color: $grey-text;
    font-size: 12px;
    margin: 0;

    @include media(mediumScreen, bigScreen) {
      font-size: 14px;
    }
  }

  .bottomNavigation {
    display: flex;
    background-color: white;
    align-items: center;
    justify-content: center;
    padding: 10px;
    gap: 20px;

    @include media(mediumScreen, bigScreen) {
      height: 50px;
    }

    button > img {
      width: 30px;
      height: 30px;

      @include media(mediumScreen, bigScreen) {
        width: 40px;
        height: 40px;
      }
    }
  }
}
