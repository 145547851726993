@use '../../../styles/_sizes' as *;
@use '../../../styles/_mediaqueries' as *;
@use '../../../styles/_colors' as *;

.modal {
  display: none;
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.7);
}

.active {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContent {
  width: 90%;

  @include media(mediumScreen, bigScreen) {
    width: 70%;
    max-width: calc($max-width - 20px);
  }
}
