$dark-background: #303030;
$dark-secondary-background: #525252;
$dark-accent: #f4b324;
$dark-text: #f6e7d8;
$dark-code-text: #c792ea;

$light-background: #ffce6d;
$light-secondary-background: $dark-text;
$light-accent: #ff5055;
$light-text: #291b25;
$light-code-text: #39adb5;

$grey-text: #9f8f7f;

:export {
  lightBackground: $light-background;
  lightSecondaryBackground: $light-secondary-background;
  lightAccent: $light-accent;
  lightText: $light-text;
  darkBackground: $dark-background;
  darkSecondaryBackground: $dark-secondary-background;
  darkAccent: $dark-accent;
  darkText: $dark-text;
}
