@use '../../../styles/typography' as *;
@use '../../../styles/colors' as *;

.articleList {
  a {
    @include subHeading;
    font-weight: 600;
  }
  p {
    margin-top: 0.5em;
    margin-bottom: 2em;
  }
}
